import settings from '@/settings';
import {computed} from "vue";

export default function() {

    const getLanguageIsoFromId = ( languageId ) => {
        return Object.keys(settings.language).find(key => settings.language[key] === languageId);
    }

    const availableLanguages = settings.availableLanguages;

    const defaultLanguage = settings.defaultLanguage;

    const defaultGpsrTranslations = settings.defaultGpsr;

    const getLanguageMetaTitle = ( language ) => {
        return settings.metaStrings[ language ].title;
    }

    const getLanguageMetaDescription = ( language ) => {
        return settings.metaStrings[ language ].description;
    }

    const getTaxId = ( key ) => {
        return settings.taxIDs[ key ];
    }

    return {
        getLanguageIsoFromId,
        availableLanguages,
        defaultLanguage,
        defaultGpsrTranslations,
        getLanguageMetaTitle,
        getLanguageMetaDescription,
        getTaxId
    }
}