import ProductRepository from "@/repositories/ProductRepository"
import UserRepository from "@/repositories/UserRepository"
import CategoryRepository from "@/repositories/CategoryRepository"
import PropertyGroupRepository from "@/repositories/PropertyGroupRepository"
import TaxRepository from "@/repositories/TaxRepository";
import PropertyGroupOptionRepository from "@/repositories/PropertyGroupOptionRepository";
import ManufacturerRepository from "@/repositories/ManufacturerRepository";
import DeliveryTimeRepository from "@/repositories/DeliveryTimeRepository";
import OrderRepository from "@/repositories/OrderRepository";
import StatemachineStateRepository from "@/repositories/StatemachineStateRepository";
import DocumentRepository from "@/repositories/DocumentRepository";
import ExtensionRepository from "@/repositories/ExtensionRepository";

const repositories = {
    products: ProductRepository,
    users: UserRepository,
    categories: CategoryRepository,
    propertygroups: PropertyGroupRepository,
    taxes: TaxRepository,
    propertygroupoptions: PropertyGroupOptionRepository,
    manufacturer: ManufacturerRepository,
    deliverytime: DeliveryTimeRepository,
    orders: OrderRepository,
    statemachinestate: StatemachineStateRepository,
    documents: DocumentRepository,
    extensions: ExtensionRepository
}

export const RepositoryFactory = {
    get: name => repositories[name]
}
