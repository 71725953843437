import {useAPI} from "@/composables/useAPI";
const {post, isLoading} = useAPI()

export default {
    async getPropertyOptionDetail(id) {
        const body = {
            "ids": id,
            "page": 1,
            "total-count-mode": 1
        }
        body.sort = [{
            "field": "property_group_option.name",
            "naturalSorting": false,
            "order": "ASC"
        }];
        const params = new URLSearchParams({})
        let result = await post('/search/property-group-option', params, body)

        //result = unpackJSONAPI(result, whitelist);

        //console.log(result)
        return result

    },

    async getPropertyOptionName(id) {
        const body = {
            "ids": id,
            "page": 1,
            "total-count-mode": 1
        }
        body.sort = [{
            "field": "property_group_option.name",
            "naturalSorting": false,
            "order": "ASC"
        }];
        const params = new URLSearchParams({})
        let result = await post('/search/property-group-option', params, body)

        if (result.data) {
            return result.data[0].attributes.name
        }
        return ''
    },


    async delete(payload) {
        const body = {
            'write-property': {
                entity: 'product_property',
                action: 'delete',
                payload: payload
            }
        }

        const params = new URLSearchParams
        return await post('/_action/sync', params, body)
    },


    loading() {
        return isLoading.value
    }
}
