import {useAPI} from "@/composables/useAPI";
import settings from "@/settings";
import {useRoles} from "@/composables";

const {post, patch, callUrlRawResponse, currentUser, isLoading} = useAPI();
const { shopConfig } = useRoles();

export default {

    async getOrders(filter, sort, page, limit) {

        let salesChannelId = shopConfig.value.sales_channel

        const body = {
            "page": page,
            "limit": limit,
            "filter":
                [
                    {
                        "type": "equalsAny",
                        "field": "salesChannel.id",
                        "value": salesChannelId
                    }
                ],
            "associations":
                {
                    "addresses":
                        {
                            "total-count-mode": 1
                        },
                    "billingAddress":
                        {
                            "total-count-mode": 1,
                            "associations":
                                {
                                    "country":
                                        {
                                            "total-count-mode": 1
                                        }
                                }
                        },
                    "salesChannel":
                        {
                            "total-count-mode": 1
                        },
                    "orderCustomer":
                        {
                            "total-count-mode": 1
                        },
                    "currency":
                        {
                            "total-count-mode": 1
                        },
                    "documents":
                        {
                            "total-count-mode": 1
                        },
                    "transactions":
                        {
                            "sort":
                                [
                                    {
                                        "field": "createdAt",
                                        "order": "DESC",
                                        "naturalSorting": false
                                    }
                                ],
                            "associations":
                                {
                                    "paymentMethod":
                                        {
                                            "total-count-mode":1
                                        }
                                },
                            "total-count-mode": 1
                        },
                    "deliveries":
                        {
                            "total-count-mode": 1,
                            "associations":
                                {
                                    "shippingMethod":
                                        {
                                            "total-count-mode":1
                                        },
                                    "shippingOrderAddress":
                                        {
                                            "associations": {
                                                "country":
                                                    {
                                                        "total-count-mode": 1
                                                    }
                                            }
                                        }
                                }
                        },
                    "swagSocialShoppingOrder":
                        {
                            "associations":
                                {
                                    "salesChannel":
                                        {
                                            "total-count-mode": 1
                                        }
                                },
                            "total-count-mode": 1
                        },
                    "lineItems":
                        {
                            "associations": {
                                "product": {
                                    "associations": {
                                        "manufacturer": {
                                            "total-count-mode": 1
                                        }
                                    },
                                    "total-count-mode": 1
                                }
                            },
                            "total-count-mode": 1,
                        }
                },
            "total-count-mode": 1
        }

        for (const [fkey, frow] of Object.entries(filter)) {
            let type = 'contains'
            if (frow.matchMode) {
                type = frow.matchMode
            }
            if (frow.value && frow.value !== '') {
                body.filter.push({
                    "field": fkey,
                    "type": type,
                    "value": frow.value
                })
            }
        }

        if (sort.field) {
            body.sort = [
                {
                    "field": sort.field,
                    "order": sort.order,
                    "naturalSorting": false
                }
            ]
        } else {
            body.sort = [
                {
                    "field": "orderDateTime",
                    "order": "DESC",
                    "naturalSorting": false
                }
            ]
        }

        const params = new URLSearchParams({})
        let result = await post('/search/order', params, body, true, 'simple')
        result.data.forEach((row, index) => {
            if (!row.customFields) {
                result.data[index].customFields = {"shipping_provider": ''}
            }
            /*
            result.data[index].lineItems.forEach((lrow, lindex) => {
                // get Manufacturer Name
            })*/
        })
        //console.log(result)
        // Set Shipping provider customField to '' if not set

        return result
    },

    async patchOrder(orderId, body) {

        const params = new URLSearchParams()
        return await patch('/order/'+orderId, params, body, true, true)

    },

    async downloadDocument(documentId, deepLinkCode, documentName = 'document') {
        const res = await callUrlRawResponse(process.env.VUE_APP_API_BASEURL+'/_action/document/'+documentId+'/'+deepLinkCode+'?download=1')
        const blob = await res.blob()
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', `${documentName}.pdf`);
        document.body.appendChild(link);
        link.click();
    },

    loading() {
        return isLoading.value
    }
}
