import {computed, ref} from "vue";
import { useAPI } from "@/composables/useAPI";
import settings from '@/settings';
import { ROLES } from '@/constants/roles';

export default function() {
    const { currentUser } = useAPI();

    const roleName = computed(() => currentUser.value.acl_roles[0].attributes.name )

    const shopConfig = computed(() => settings[roleName.value] );

    const isUsAdmin = computed(() => roleName.value === ROLES.ADMIN_US);
    const isFrAdmin = computed(() => roleName.value === ROLES.ADMIN_FR);

    return {
        roleName,
        shopConfig,
        isUsAdmin,
        isFrAdmin
    }
}