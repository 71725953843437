import {useAPI} from "@/composables/useAPI";

const {post, isLoading} = useAPI()

export default {
    async getManufacturers(ids) {

        const body = {
            "page": 1,
            "limit": 500,
            "sort":
                [
                    {
                        "field": "name",
                        "order": "ASC",
                        "naturalSorting": false
                    }
                ],
            "total-count-mode": 1
        }

        if (ids) {
            const idsJoined = ids.join('|')
            body.filter = [
                {
                    "field": "id",
                    "type": "equalsAny",
                    "value": idsJoined
                }
            ]
        }

        const params = new URLSearchParams({})
        return await post('/search/product-manufacturer/', params, body)
    },

    async getManufacturerById(id) {
        const body = {
            "ids": id,
            "page": 1,
            "total-count-mode": 1
        }
        const params = new URLSearchParams({})
        return await post('/search/product-manufacturer', params, body)
    },

    async getManufacturerNameById(id) {
        const manufacturer = await this.getManufacturerById(id)

        if (manufacturer.data[0]) {
            return manufacturer.data[0].attributes.name
        }
    },

    loading() {
        return isLoading.value
    },


}
