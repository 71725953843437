import {useAPI} from "@/composables/useAPI";
const {get, post} = useAPI()
import {SWHelpers} from "@/helpers/SWHelpers";

const {findInclude} = SWHelpers()

export default {

    async getUser(username) {

        const body = {
            "page": 1,
            "limit": 25,
            "associations":
            {
                "accessKeys":
                {
                    "total-count-mode": 1
                },
                "locale":
                {
                    "total-count-mode": 1
                },
                "aclRoles":
                {
                    "total-count-mode": 1
                },
                "customUserExtension":
                {
                    "total-count-mode":1
                }
            },
            "query": [
                {
                    "score": 500,
                    "query":
                        {
                            "type": "equals",
                            "field": "user.username",
                            "value": username
                        }
                },
            ],
            "total-count-mode": 1
        }

        const params = new URLSearchParams({})
        return await post('/search/user', params, body)
    },

    async getMe() {
        const params = new URLSearchParams({})
        const result = await get('/_info/me', params);

        ///const acl_roles = findAssociationById(result.data.id, result.included, "acl_role")
        result.data.acl_roles = findInclude(result.included, "acl_role", ["type"])
        result.data.leicaclassic_user_extension = findInclude(result.included, "leicaclassic_user_extension", ["type"])

        return result
    }
}
