
const findInclude = function(data, search, keys) {

    return data.filter(row =>
        keys.some(key =>
            String(row[key]).includes(search)
        )
    )
}

const findAssociationById = function(id, data, type = '') {

    return data.filter(row => {
        if (type !== '') {
            row.id === id
        } else {
            row.id === id && row.type === type
        }
    })
}

/**
 * zerlegt die von shopware gesendete JSON:API Struktur und gibt zusätzlich Daten aus dem included Bereich direkt in den Payload Bereich zurück
 * @param result
 * @param whitelist
 * @returns {*}
 */
const unpackJSONAPI = function(result, whitelist) {
    result.data.forEach((row, index) => {

        // eslint-disable-next-line no-unused-vars
        Object.entries(row.relationships).forEach((relRow) => {
            // Das funktioniert nur für Arrays -> die Keys die wir suchen werden whitegelistet
            const relKey = relRow[0]
            if (whitelist.includes(relKey)) {
                if (relRow[1].data && relRow[1].data.length > 0) {
                    // eslint-disable-next-line no-unused-vars
                    relRow[1].data.forEach((dataRow, dataIndex) => {
                        // Find Association in included Array

                        const found = findInclude(result.included, dataRow.id, ["id"])
                        if (found && found.length > 0) {

                            found.forEach((foundRow) => {
                                if (foundRow.type === dataRow.type || (foundRow.type === 'media' && dataRow.type === 'product_media')) {
                                    if (Array.isArray(result.data[index].relationships[relKey].found)) {
                                        result.data[index].relationships[relKey].found.push(foundRow)
                                    } else {
                                        result.data[index].relationships[relKey].found = [foundRow]
                                    }
                                }
                            })
                        }
                    })
                }
            }
        })

        // Die Cover URL holen wir uns direkt über ein MEDIA Include
        if (row.relationships.cover && row.relationships.cover.data && row.relationships.cover.data.id !== '') {
            const coversFound = findInclude(result.included, row.relationships.cover.data.id, ["id"])

            if (coversFound && coversFound.length > 0) {
                result.data[index].relationships.cover.found = coversFound.filter(crow => crow.type === 'media')

                if (result.data[index].relationships.cover.found.length === 0 && coversFound[0].type === 'product_media') {
                    // Wenn product_media und media nicht dieselbe ID haben, müssen wir den Umweg über ProductMedia

                    const mediaFound = findInclude(result.included, coversFound[0].attributes.mediaId, ["id"])
                    if (mediaFound && mediaFound.length > 0) {
                        result.data[index].relationships.cover.found = mediaFound.filter(crow => crow.type === 'media')
                    }
                }
            }
        }
    })

    return result
}

/*
* find a path in the CategoryTree via key
* */
const findInTree = (key, tree, path = "") => {

    for (let index = 0; index < tree.length; index++) {
        let node = tree[index]

        if (node.key === key) {
            let foundPath = String(index)
            path = path.concat(foundPath)
            return {result: node, path}
        } else {
            if (node.children && node.children.length > 0) {

                let tmp = findInTree(key, node.children, path+""+index+".children.")
                if (tmp.path) {
                    return tmp;
                }
            }
        }
    }
    return {};
}

/*
* resolve object in Tree via path o.children.0.children.2 etc.
* */
function resolve(path, obj=self, separator='.') {
    var properties = Array.isArray(path) ? path : path.split(separator)
    return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

/**
 * set path in object via string
 * @param object
 * @param path
 * @param value
 * @returns {*}
 */
const setPath = (object, path, value) => path
    .split('.')
    .reduce((o,p,i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object)

const buildVisibilities = () => {

}

export function SWHelpers() {
    return {
        findInclude,
        findAssociationById,
        unpackJSONAPI,
        findInTree,
        resolve,
        setPath
    }
}
