import {useAPI} from "@/composables/useAPI";

const {post, get, patch, callUrl, currentUser, isLoading} = useAPI()

export default {
    async getDocumentsForOrder(orderId) {

        const body = {
            "page": 1,
            "limit": 25,
            "term": "",
            "filter":
                [
                    {
                        "type": "equals",
                        "field": "order.id",
                        "value": orderId
                    }
                ],
            "sort":
                [
                    {
                        "field": "createdAt",
                        "order": "DESC",
                        "naturalSorting": false
                    }
                ],
            "associations":
                {
                    "documentType":
                        {
                            "total-count-mode": 1
                        }
                },
            "total-count-mode": 1
        }

        const params = new URLSearchParams({})
        return await post("/search/document", params, body, false, "simple")
    }
}
