import {useAPI} from "@/composables/useAPI";

const { post } = useAPI()

export default {
    async upsert(extension_name, payload) {
        const body = {
            'write-extension': {
                entity: extension_name,
                action: 'upsert',
                payload: [payload]
            }
        }

        const params = new URLSearchParams();
        return await post('/_action/sync', params, body)

    },
}