import {useAPI} from "@/composables/useAPI";

const {get, post, isLoading} = useAPI()

export default {

    async getDeliveryTimes() {

        const params = new URLSearchParams({})
        return await get('/delivery-time', params)
    },

    async getDeliveryTimeById(id) {
        const body = {
            "ids": id,
            "page": 1,
            "total-count-mode": 1
        }
        const params = new URLSearchParams({})
        return await post('/search/delivery-time', params, body)
    },

    async getDeliveryTimeNameById(id) {
        const deliveryTime = await this.getDeliveryTimeById(id)

        if (deliveryTime.data[0]) {
            return deliveryTime.data[0].attributes.name
        }
    },

    loading() {
        return isLoading.value
    },
}
